const Localizer = {

  getNumberString: (number, numberOfMinDecimals, useZeroDecimalsIfInteger) => {
    let numberOfDecimals = numberOfMinDecimals;

    if (useZeroDecimalsIfInteger === true) {
      const isInteger = parseInt(number) === number;
      if (isInteger) {
        numberOfDecimals = 0;
      }
    }

    return Localizer.getNumberStringForLanguage(number, numberOfDecimals, navigator.language);
  },

  getNumberStringForLanguage: (number, numberOfDecimals, language) => {
    if (!language) {
      language = 'en';
    }

    if (!number) {
      return Number(0).toLocaleString(language, { minimumFractionDigits: numberOfDecimals, maximumFractionDigits: numberOfDecimals });
    }

    return Number(number).toLocaleString(language, { minimumFractionDigits: numberOfDecimals, maximumFractionDigits: numberOfDecimals });
  },

  getDecimalSeparator: (locale) => {
    const formatter = new Intl.NumberFormat(locale);
    const parts = formatter.formatToParts(1234.5);
    return parts.find(part => part.type === 'decimal')?.value || '.';
  },

  getThousandsSeparator: (locale) => {
    const formatter = new Intl.NumberFormat(locale);
    const parts = formatter.formatToParts(1234.5);
    return parts.find(part => part.type === 'group')?.value || '';
  },

  parseFloat: (stringValue) => {
    if (!stringValue) {
      return 0;
    }

    const thousandsSeparator = Localizer.getThousandsSeparator();
    const decimalSeparator = Localizer.getDecimalSeparator();

    const normalizedNumber = stringValue
        .split(thousandsSeparator).join('')
        .split(decimalSeparator).join('.');

    const floatValue = parseFloat(normalizedNumber);

    console.log(`Parse float, stringValue: ${stringValue}, floatValue: ${floatValue}, thousandsSeparator: ${thousandsSeparator}, decimalSeparator: ${decimalSeparator}`);

    return floatValue;
  }

};

export default Localizer;
